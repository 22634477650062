import { ClaimParams } from '@apps-orangefi/hooks'
import { useClaimStrykeRewards } from '@apps-orangefi/wagmi/hooks'
import { useCallback } from 'react'

export const useClaimStrykeRewardsForm = (
  distributorAddress: AddressType | undefined,
  myClaims: ClaimParams,
  isClaimable: boolean,
  refetch: () => void
) => {
  const claim = useClaimStrykeRewards(
    distributorAddress,
    myClaims.vaultAddressList,
    myClaims.rewardTokenAddressList,
    myClaims.amounts,
    myClaims.merklProofsList,
    isClaimable,
    {
      success: () => {
        setTimeout(() => {
          refetch()
        }, 5000)
      },
    }
  )

  const onClaim = useCallback(() => {
    claim.onClaim()
  }, [claim.onClaim])

  return {
    onClaim,
    isClaimReady: claim.isClaimReady,
  }
}
