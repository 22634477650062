import { useDebounce } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { OrangeDistributorABI } from '@apps-orangefi/wagmi/abis'
import { isEmpty } from 'lodash'
import { useMemo, useEffect, useState } from 'react'
import {
  useSimulateContract,
  useWriteContract,
  useWaitForTransactionReceipt,
  BaseError,
} from 'wagmi'

export const useClaimStrykeRewards = (
  distributorAddress: AddressType | undefined,
  vaultAddressList: AddressType[],
  rewardTokenAddressList: AddressType[],
  amounts: BN[],
  merklProofsList: AddressType[][],
  isClaimable: boolean,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isClaimReady, setIsClaimReady] = useState(false)

  const args = useMemo(() => {
    return [
      vaultAddressList,
      rewardTokenAddressList,
      amounts?.map(amount => amount.pow10(18).convertBigint()),
      merklProofsList,
    ] as const
  }, [vaultAddressList, rewardTokenAddressList, amounts, merklProofsList])

  const enabled = useMemo(() => {
    return (
      isClaimable &&
      !!distributorAddress &&
      !isEmpty(vaultAddressList) &&
      !isEmpty(rewardTokenAddressList) &&
      !isEmpty(amounts) &&
      !isEmpty(merklProofsList)
    )
  }, [
    isClaimable,
    distributorAddress,
    vaultAddressList,
    rewardTokenAddressList,
    amounts,
    merklProofsList,
  ])

  const { data, error: simulateError } = useSimulateContract({
    address: distributorAddress!,
    abi: OrangeDistributorABI,
    functionName: 'batchClaim',
    args,
    query: {
      enabled,
    },
  })

  useEffect(() => {
    if (simulateError) {
      console.debug('error occurred useClaimStrykeRewards: ', simulateError)
    }
  }, [simulateError])

  const { data: hash, writeContract } = useWriteContract({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceipt({ hash })

  useEffect(() => {
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
      }
    }
  }, [isSuccess, isError, error, callback])

  useEffect(() => {
    setIsClaimReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isClaimReady,
    hash,
    isSuccess,
    onClaim: () => {
      if (!isClaimReady) {
        return
      }
      writeContract(data!.request)
    },
  }
}
